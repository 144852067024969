<template>
  <div class="home">
    <v-row align="center" justify="center" style="height: 93vh">
      <v-col class="text-center" cols="12 white--text">
        <h1 class="font-main font-weight-thin mb-4">
          ยินดีต้อนรับเข้าสู่ระบบบริหารจัดการ
        </h1>
        <h4 class="subheading">
          {{ this.$store.state.listClub[0]==undefined?"":this.$store.state.listClub[0].club_name }}
        </h4>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
<style lang="scss" scoped>
.theme--dark,
.v-application {
  background: transparent !important;
  color: #ffffff;
}
</style>
